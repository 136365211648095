<template>
    <div v-loading="loading"  element-loading-text="加載內容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="title-group">
            <h2>圖片</h2>
            <!-- <button @click="all">{{title}}</button> -->
        </div>
        <div class="img-container" v-for="img_container in img" :key="img_container">
            <!-- <template> -->
            <template v-for="(imgs,index) in otherFiles" :key="imgs">
                <img v-if="index >= ((img_container * 4) - 4) && index <= (img_container * 4) - 1 && ((all_state === false && img_container === 1) || (all_state === true && img_container >= 1))" :src="env_url+imgs.file"/>
            </template>
            <!-- </template> -->
        </div>
        <div class="org-serial-number" v-if="all_state === true">
            <div class="content" v-html="project_s.introduction" style="white-space: pre-wrap;"></div>
        </div>

    </div>
</template>

<script>
import { toRef } from "vue";
import api from "@/api";
export default {
    data() {
        return {
            // 所有的图片（点击显示全部之后显示的）
            otherFiles:{},
            project_s:{},
            loading:false,
            img:0,
            // 每一行顯示多少張圖片
            count:4,
            error:'',
            all_state:true,
            title:'顯示全部',
            env_url:process.env.VUE_APP_API_SERVER,
        };
    },
    methods:{
        // all(){
        //     if(this.all_state === true){
        //         this.all_state = false;
        //         this.title = '展示全部';
        //     }else{
        //         this.all_state = true;
        //         this.title = '收起';
        //     }
        // }
    },
    computed: {
        // 点击显示全部之前显示在外面的图片
        coverImages() {
            return this.images.slice(0, 4);
        },
    },
    created:function (){
         let id = this.$route.params.id;
         this.loading = true;
         api.fundraise.getFundraiseDetails(id).then(response => {
            let otherFiles = response.data.personal.otherFiles;
            this.otherFiles = otherFiles;
            this.img = Math.ceil(otherFiles.length / this.count);
            this.project_s = response.data.project;
            this.loading = false;
         }).catch(error=>{
            this.loading = false;
            this.$message.error('求助内容數據異常，請重新獲取！');
         });
    },
    watch:{
    }
    
};
</script>

<style lang="scss" scoped>
.org-serial-number {
        grid-column: 1 / 3;
        font-weight: 600;
        color: $gray-400;
        word-break: break-all;
    }
.title-group {
    @include fr-b-b;
    margin: 16px 0;
    h2 {
        font-size: 20px;
        font-weight: 600;
    }
    button {
        text-decoration: underline;
        color: $green-500;
        cursor: pointer;
    }
}
.img-container {
    @include fr-b-c;
    margin: 16px 0;
    img {
        width: 20%;
        // (屏幕宽度-侧边栏宽度-边距（gap, padding之类）*3) / 5（因为width: 20%）
        height: calc((100vw - 300px - 16px * 3) / 5);
        object-fit: cover;
        background-color: $gray-300;
    }
}
.content {
    margin: 16px 0;
    p {
        margin-bottom: 16px;
    }
}
@media screen and (max-width: 900px) {
    .img-container {
        img {
            width: 30%;
            height: calc((100vw - 16px * 2) * 0.3);
            &:last-child {
                display: none;
            }
        }
    }
}
</style>